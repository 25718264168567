import React from "react";
import { Link, StaticQuery, graphql } from "gatsby";
import g from "glamorous";
import { css } from "glamor";
import Img from "gatsby-image";
import { FaPlus } from "react-icons/fa";

const Div = g.div({
  borderRadius: `0 0 4px 4px`,
  backgroundColor: `white`,
  color: `#1f1f1f`,
  border: `1px solid rgba(73,105,126,0.08)`,
  boxShadow: `0 1px 7px rgba(0,0,0,.05)`,
  margin: `2em 0`,
  transition: `all 0.15s ease-in-out`,
  ":hover": {
    border: `1px solid rgba(73,105,126,0.18)`,
    boxShadow: `0 1px 9px rgba(0,0,0,.2)`,
    transition: `all 0.1s ease-in-out`,
    transform: `translateY(-2px)`
  }
});

const Title = g.div({
  padding: `12px`,
  margin: `0 auto 0em`,
  letterSpacing: `0.05em`,
  fontSize: `1.15em`,
  color: `#49697e`,
  fontWeight: `bold`
});

const cssImage = css({
  width: `100%`
});

const More = g.div({
  color: `#49697e`,
  fontSize: `14px`,
  padding: `.2em 1em .6em`,
  textAlign: `right`,
  margin: `0.3em 0 .4em`
});

export default () => (
  <StaticQuery
    query={graphql`
      query videoEn {
        file(
          relativePath: { eq: "images/video-chirurgie-esthetique-paris.jpg" }
        ) {
          childImageSharp {
            resize(width: 1500, height: 1500) {
              src
            }
            fluid(maxWidth: 400) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (
      <Link to="/video-chirurgie-esthetique/">
        <Div>
          <Title>Videos</Title>
          <Img
            fluid={data.file.childImageSharp.fluid}
            {...cssImage}
            alt="See all videos about plastic surgery with Dr Cédric Kron, plastic surgeon in Paris"
          />
          <More>
            <FaPlus
              css={{
                fontSize: `1em`,
                padding: `0 .3em 0.1em`,
                transform: `translateY(3px)`
              }}
            />
            All the videos (in French)
          </More>
        </Div>
      </Link>
    )}
  />
);
