import React from "react";
import { Link, StaticQuery, graphql } from "gatsby";
import { css } from "glamor";
import g from "glamorous";
import Img from "gatsby-image";
import { Row, Col } from "glamorous-grid";
import { FaGraduationCap } from "react-icons/fa";

export default () => (
  <StaticQuery
    query={graphql`
      query portraitEn {
        file(
          relativePath: {
            eq: "images/dr-cedric-kron-chirurgie-esthetique-paris.jpg"
          }
        ) {
          childImageSharp {
            resize(width: 1500, height: 1500) {
              src
            }
            fluid(maxWidth: 400) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    `}
    render={(data) => (
      <BioWrapper>
        <Row>
          <Col span={{ xs: 12 / 12 }}>
            <H2>Foreword</H2>
          </Col>
          <Col span={{ xs: 12 / 12, lg: 40 / 100 }}>
            {" "}
            <Img
              fluid={data.file.childImageSharp.fluid}
              css={{
                "@media(max-width: 767px)": {
                  marginBottom: `.8em`,
                },
              }}
              alt="Portrait of Dr Cédric Kron, cosmetic surgeon in Paris"
            />
            <Link
              {...cssButton}
              to="/en/plastic-surgeon-paris/"
              title="Titles and diplomas of Dr. Kron, cosmetic surgeon in Paris"
            >
              <FaGraduationCap
                css={{
                  marginRight: `10px`,
                  marginBottom: `3px`,
                  transform: `translateY(5px)`,
                }}
              />
              See titles and diplomas
            </Link>
          </Col>
          <Col span={{ xs: 12 / 12, lg: 60 / 100 }}>
            <H3>
              Aesthetic surgeon qualified in Aesthetic Surgery, Plastic and
              Reconstructive exercising in Paris.
            </H3>

            <p>
              I perform all procedures that cover my specialty:{" "}
              <Link to="/en/breast/">breast surgery</Link>(
              <Link to="/en/breast/breast-implants/">breast implants</Link>,{" "}
              <Link to="/en/breast/breast-lift/">breast lift</Link>), face (
              <Link to="/en/face/facelift-lvpa/">face lift</Link>,
              <Link to="/en/eyes/blepharoplasty-eyelid-surgery/">
                blepharoplasty
              </Link>
              ,{" "}
              <Link to="/en/ears/otoplasty-prominent-ears-surgery/">
                prominent ear surgical correction
              </Link>
              ) and <Link to="/en/body-contouring/">body contouring</Link> (
              <Link to="/en/body-contouring/tummy-tuck/">
                tummy tuck surgery
              </Link>
              ,<Link to="/en/body-contouring/liposuction/">liposuction</Link>,{" "}
              <Link to="/en/body-contouring/body-lift/">body lift</Link>).
            </p>
            <p>
              I also perform aesthetic medical treatments that have been proven{" "}
              <strong>safe and effective</strong>:{" "}
              <Link to="/en/botox-injections/">Botox</Link> and{" "}
              <Link to="/en/hyaluronic-acid/">hyaluronic acid injections</Link>,
              skin rejuvenation treatments (such as peels, laser, radio
              frequency or <Link to="/en/face/hydrafacial/">HydraFacial</Link>)
              as well as as{" "}
              <Link to="/en/fat-removal/">
                non-invasive fat suppression treatments
              </Link>
              (such as{" "}
              <Link to="/en/body-contouring/cryolipolysis-coolsculpting/">
                CoolSculpting
              </Link>{" "}
              and <Link to="/en/body-contouring/emsculpt/">EMSculpt</Link>
              ).
            </p>
            <p>
              I work as a cosmetic surgeon exclusively in the private sector,
              which allows me to take care of my patients{" "}
              <strong>24 hours a day </strong>
              when necessary.
            </p>
          </Col>

          <Col>
            <p>
              I welcome patients in my own medical office located in Paris
              (17th) dedicated to a <strong>"tailor-made" approach</strong> of
              aesthetic treatments for the face and the body.
            </p>

            <p>
              The surgical procedures are always taking place in{" "}
              <strong>approved clinics for plastic and cosmetic surgery</strong>{" "}
              in Paris, in particular the Nouvelle Clinique d'Esthétique des
              Champs-Elysées (Paris 8th).
            </p>

            <p>
              I consider a treatment successful when it can achieve a{" "}
              <strong>natural result</strong>, harmonious with the entire body.
              It is essential for me to fully understand the needs of my patient
              and adapt my response to it. One of the pitfalls of cosmetic
              surgery is to try matching stereotypes that are actually
              antithetic with the idea of ​​beauty and seduction. In parallel of
              this vision, I seek to use techniques that allow a fast recovery
              and simple follow-ups.
            </p>
            <p
              css={{
                textAlign: `right`,
                fontSize: `1.1em`,
                color: `#3c4d68`,
                marginRight: `5em`,
                fontWeight: `500`,
                "@media(max-width: 767px)": {
                  marginRight: `0.5em`,
                },
              }}
            >
              Dr Cédric Kron
            </p>
          </Col>
        </Row>
      </BioWrapper>
    )}
  />
);

const BioWrapper = g.section({
  backgroundColor: `white`,
  marginBottom: `3em`,
  borderRadius: `6px`,
  border: `1px solid rgba(73,105,126,0.08)`,
  boxShadow: `0 1px 7px rgba(0,0,0,.05)`,
  padding: `1em 1.25em 1.5em 1.5em`,
  "@media(max-width: 767px)": {
    padding: `.8em`,
  },
});

const H2 = g.h2({
  position: `relative`,
  fontSize: `32px`,
  fontWeight: `500`,
  marginTop: `0.2em`,
  marginBottom: `.5em`,
  "&::after": {
    content: ` `,
    position: `absolute`,
    top: `54%`,
    left: `155px`,
    border: `.063em solid #3c4d68`,
    display: `inline-block`,
    width: `900px`,
  },
  "@media(max-width: 767px)": {
    margin: `.25em 0 .6em`,
    "&::after": {
      content: `none`,
    },
  },
  "@media(max-width: 1100px)": {
    "&::after": {
      content: `none`,
    },
  },
});

const H3 = g.h3({
  color: `#1f1f1f`,
  margin: `0 0 0.2em`,
  font: `500 16px/26px -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'`,
});

const cssButton = css({
  color: `white`,
  backgroundColor: `#3c4d68`,
  border: `1px solid #3c4d68`,
  padding: `.4em 1.5em`,
  display: `block`,
  margin: `1em 0 12px`,
  borderRadius: `5px`,
  textAlign: `center`,
  fontWeight: `500`,
  fontSize: `15px`,
  minWidth: `215px`,
  float: `right`,
  textDecoration: `none`,
  letterSpacing: `.03em`,
  transition: `all 0.3s ease-in-out 0s`,
  ":hover": {
    backgroundColor: `white`,
    color: `#3c4d68`,
    transition: `all 0.3s ease-in-out 0s`,
  },
});
