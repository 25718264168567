import React from "react";
import { Link } from "gatsby";
import g from "glamorous";
import { css } from "glamor";
import { FaMapMarker } from "react-icons/fa/";
import { FaEnvelope } from "react-icons/fa";
import { FaPhone } from "react-icons/fa";

const mediaQueries = {
  phone: "@media only screen and (max-width: 991px)"
};

const Div = g.div({
  borderRadius: `0 0 4px 4px`,
  backgroundColor: `white`,
  color: `#3c4d68`,
  padding: `1em`,
  border: `1px solid rgba(0,0,0,0)`,
  boxShadow: `0 1px 7px rgba(0,0,0,.05)`
});

const Title = g.div({
  margin: `0 auto .35em`,
  letterSpacing: `0.06em`,
  fontSize: `1.2em`,
  color: `#3c4d68`,
  fontWeight: `bold`
});

const Item = g.div({
  fontSize: `.85em`,
  lineHeight: `2em`,
  marginBottom: `.2em`,
  [mediaQueries.phone]: {
    fontSize: `.95em`
  }
});

const cssLink = css({
  color: "#3c4d68",
  fontSize: `1em`,
  textDecoration: `none`,
  borderBottom: `none`,
  transition: `all .31s`,
  padding: `0 0 3px 0`,
  margin: `0`,
  position: `relative`,
  "::before": {
    content: " ",
    position: `absolute`,
    width: `100%`,
    height: `1px`,
    bottom: `0`,
    left: `0`,
    backgroundColor: `#3c4d68`,
    visibility: `invisible`,
    transform: `scaleX(0)`,
    transition: `all 0.3s ease-in-out 0s`
  },
  ":hover": {
    transition: `all .3s`,
    border: `none`,
    background: `none`,
    padding: `0 0 3px 0`,
    margin: `0`,
    "::before": {
      visibility: `visible`,
      transform: `scaleX(1)`,
      transition: `all 0.3s ease-in-out 0s`
    }
  }
});

css.global(".svg-icon", { paddingBottom: ".1em" });
css.global(".svg-icon", { marginRight: ".7em" });

export default ({ children }) => (
  <Div>
    <Title>Our address</Title>
    <Item>
      <a
        href="https://goo.gl/maps/ZPEQycbj3W42"
        {...cssLink}
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaMapMarker
          size={`14`}
          className="svg-icon"
          css={{
            transform: `translateY(3px)`
          }}
        />
        57 avenue de Villiers - Paris 17e
      </a>
    </Item>
    <Item>
      <a href="tel:+33145628500" {...cssLink}>
        <FaPhone
          size={`14`}
          className="svg-icon"
          css={{
            transform: `translateY(3px)`
          }}
        />
        (+33) 1 45 62 85 00
      </a>
    </Item>
    <Item>
      <Link to="/en/contact-us/" {...cssLink}>
        <FaEnvelope
          size={`14`}
          className="svg-icon"
          css={{
            transform: `translateY(3px)`
          }}
        />
        Contact-us
      </Link>
    </Item>
  </Div>
);
