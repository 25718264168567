import React from "react";
import { Link, StaticQuery, graphql } from "gatsby";
import g from "glamorous";
import { css } from "glamor";
import Img from "gatsby-image";
import { Row, Col } from "glamorous-grid";
import Slider from "react-slick";
import { GoQuote } from "react-icons/go";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Wrapper = g.section({
  backgroundColor: `white`,
  marginBottom: `.5em`,
  borderRadius: `6px`,
  border: `1px solid rgba(73,105,126,0.08)`,
  boxShadow: `0 1px 7px rgba(0,0,0,.05)`,
  padding: `1.25em 1em 1.5em 1.5em`,
  "@media(max-width: 767px)": {
    padding: `.8em`,
  },
});

const H3 = g.h3({
  position: `relative`,
  fontSize: `28px`,
  fontWeight: `500`,
  marginTop: `0.4em`,
  marginBottom: `1.2em`,
  "&::after": {
    content: ` `,
    position: `absolute`,
    top: `54%`,
    left: `185px`,
    border: `.063em solid #3c4d68`,
    display: `inline-block`,
    width: `318px`,
  },
  "@media(max-width: 767px)": {
    margin: `.5em 0 1.2em`,
    "&::after": {
      content: `none`,
    },
  },
  "@media(max-width: 1100px)": {
    "&::after": {
      content: `none`,
    },
  },
});

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const cssButton = css({
  color: `white`,
  backgroundColor: `#3c4d68`,
  border: `1px solid #3c4d68`,
  padding: `.4em 1.5em`,
  display: `block`,
  margin: `2em 0 10px`,
  borderRadius: `5px`,
  textAlign: `center`,
  fontWeight: `500`,
  fontSize: `15px`,
  width: `165px`,
  float: `right`,
  textDecoration: `none`,
  letterSpacing: `.03em`,
  transition: `all 0.3s ease-in-out 0s`,
  ":hover": {
    backgroundColor: `white`,
    color: `#3c4d68`,
    transition: `all 0.3s ease-in-out 0s`,
  },
});

const cssQuote = css({
  fontSize: `54px`,
  color: `#3c4d68`,
  display: `block`,
  marginLeft: `-.2em`,
  marginTop: `.25em`,
});

const P = g.div({
  width: `65%`,
  margin: `0 auto`,
  minHeight: `230px`,
  lineHeight: `25px`,
  "@media(max-width: 767px)": {
    width: `87%`,
  },
});

const Hr = g.hr({
  color: `#3c4d68`,
  backgroundColor: `#3c4d68`,
  height: `1px`,
  margin: `.8em 0 0`,
});

const Legende = g.div({
  color: `#3c4d68`,
  textAlign: `right`,
  letterSpacing: `.04em`,
  fontWeight: `600`,
  fontSize: `13px`,
  marginRight: `.2em`,
  marginBottom: `1.5em`,
});

css.global(".slick-prev:before", {
  color: `#3c4d68 !important`,
  opacity: `1 !important`,
  fontSize: `30px !important`,
});

css.global(".slick-next:before", {
  color: `#3c4d68 !important`,
  opacity: `1 !important`,
  fontSize: `30px !important`,
});

css.global(".slick-prev", {
  top: `0% !important`,
  left: `43% !important`,
  width: `30px !important`,
  height: `36px !important`,
  "@media(max-width: 767px)": {
    left: `40% !important`,
  },
});

css.global(".slick-next", {
  top: `0% !important`,
  right: `43% !important`,
  width: `30px !important`,
  height: `36px !important`,
  "@media(max-width: 767px)": {
    right: `40% !important`,
  },
});

css.insert(
  "@media(max-width: 767px) {.slick-prev {left: 40% !important;}.slick-next {right: 40% !important;}}"
);

export default () => (
  <StaticQuery
    query={graphql`
      query avantaprestemoignagesEn {
        file(
          relativePath: {
            eq: "images/photos-avant-apres-chirurgie-esthetique-paris.jpg"
          }
        ) {
          childImageSharp {
            resize(width: 1500, height: 1500) {
              src
            }
            fluid(maxWidth: 400) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    `}
    render={(data) => (
      <Wrapper>
        <Row>
          <Col span={{ xs: 12 / 12, lg: 50 / 100 }}>
            <Img
              fluid={data.file.childImageSharp.fluid}
              css={{ marginTop: `.6em`, borderRadius: `4px` }}
              alt="Before & After photos of plastic and cosmetic surgery in Paris"
            />
            <Link {...cssButton} to="/en/before-after/">
              See Before & After
            </Link>
          </Col>

          <Col span={{ xs: 12 / 12, lg: 50 / 100 }}>
            <Link to="/en/testimonials/">
              <H3>Testimonials</H3>
            </Link>
            <Slider {...settings} css={{ outline: `none` }}>
              <div>
                <P>
                  <GoQuote {...cssQuote} />
                  A cosmetic surgeon who listens and gives advice according to
                  the morphology of his patient. His goal is to get natural
                  results and that's what I was lucky to get: perfect breasts,
                  beautiful and very natural. And on top of that I experienced
                  no pain after the procedure.
                  <Hr />
                  <Legende>Breast implants</Legende>
                </P>
              </div>
              <div>
                <P>
                  <GoQuote {...cssQuote} />
                  The result is very natural, my face has regained its youth and
                  the scars have virtually disappeared just 15 days after the
                  procedure. Many thanks to Dr. Cédric Kron for his outstanding
                  work and kindness.
                  <Hr />
                  <Legende>Breast Augmentation</Legende>
                </P>
              </div>
              <div>
                <P>
                  <GoQuote {...cssQuote} />
                  I was fortunate to benefit from Dr. Kron's expertise for a
                  complete facelift. The result is amazing: visible and natural
                  after 3 days, hidden and invisible scars a month later.
                  <Hr />
                  <Legende>LVPA Facelift</Legende>
                </P>
              </div>
              <div>
                <P>
                  <GoQuote {...cssQuote} />
                  I am really happy about what you have done, it is very
                  beautiful. I am amazed, the result goes far beyond my
                  expectations, I did not imagine that it would be so pretty and
                  matching my expectations so closely.
                  <Hr />
                  <Legende>Breast surgery</Legende>
                </P>
              </div>
              <div>
                <P>
                  <GoQuote {...cssQuote} />
                  From the first visit, I realized that I was talking to a
                  doctor who is not only a great professional, but also a
                  sensitive, empathetic and intelligent. Dr. Kron never judges
                  or imposes his views.
                  <Hr />
                  <Legende>Breast implants</Legende>
                </P>
              </div>

              <div>
                <P>
                  <GoQuote {...cssQuote} />
                  I underwent several laser sessions and I am delighted with the
                  result, my skin has become brighter, plumped and my dark spots
                  have almost disappeared.
                  <Hr />
                  <Legende>Laser Skin treatment</Legende>
                </P>
              </div>
            </Slider>
          </Col>
        </Row>
      </Wrapper>
    )}
  />
);
