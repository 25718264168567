import React from "react";
import g from "glamorous";

const mediaQueries = {
  phone: "@media only screen and (max-width: 991px)",
};

const Div = g.div({
  backgroundColor: `#3c4d68`,
  color: `white`,
  padding: `1em`,
});

const Title = g.h4({
  margin: `0 auto .35em`,
  letterSpacing: `0.06em`,
  fontSize: `1.2em`,
  fontWeight: `bold`,
});

const Text = g.p({
  fontSize: `.85em`,
  lineHeight: `1.4em`,
  [mediaQueries.phone]: {
    fontSize: `1em`,
    lineHeight: `1.4em`,
  },
});

const Input = g.input({
  backgroundColor: `#3c4d68`,
  color: `white`,
  width: "100%",
  border: `none`,
  borderBottom: `2px solid white`,
  fontSize: `.85em`,
  marginBottom: `.5em`,
  transition: `all .3s`,
  ":focus": {
    letterSpacing: `.02em`,
    outline: `none`,
    transition: `all .3s`,
  },
});

const InputHidden = g.input({
  display: `none`,
});

const Button = g.input({
  backgroundColor: `3c4d68`,
  border: `2px solid white`,
  color: `#3c4d68`,
  borderRadius: `5`,
  marginTop: `.8em`,
  marginLeft: `auto`,
  padding: `.3em .7em .45em`,
  fontSize: `.8em`,
  fontWeight: `bold`,
  transition: `all .3s`,
  ":hover": {
    color: `white`,
    backgroundColor: `#3c4d68`,
    transition: `all .3s`,
  },
  ":focus": {
    outline: `none`,
    color: `white`,
    backgroundColor: `#3c4d68`,
    transition: `all .3s`,
  },
});

export default ({ ...props }) => (
  <Div>
    <Title>Get in touch</Title>
    <Text>
      If you have any question
      {props.about && <span> about {props.about}</span>}, please send us your
      contact information.
    </Text>
    <form action="https://formspree.io/f/mqkgdqrq" method="POST">
      <Input
        type="text"
        id="coordonnees"
        placeholder="Your email address or phone number"
        name="Coordonnees"
        aria-label="Your email address or phone number"
      />
      <Input
        type="textarea"
        id="intervention"
        placeholder="Subject of your request"
        name="Intervention"
        aria-label="Subject of your request"
      />
      <InputHidden
        type="textarea"
        id="page"
        value={props.about}
        name="Page"
        aria-label="Page du site consultée"
      />
      <input type="hidden" name="_language" value="en" />
      <g.Div display={`flex`} justifyContent={`space-between`}>
        <Button type="submit" value="Send" />
      </g.Div>
    </form>
  </Div>
);
